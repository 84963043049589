import React, {PureComponent} from 'react'
import { gsap } from "gsap";
import PropTypes from 'prop-types'

const INITIAL_STATE =
    {
        
    }

class Window extends PureComponent
{

    static propTypes = {
        title: PropTypes.string,
        zLevel: PropTypes.number,
        index: PropTypes.number,
        focusCallback: PropTypes.func,
        closeCallback: PropTypes.func,
        stringKey: PropTypes.string
    }

    
    constructor(props)
    {
        super(props)
        this.state = 
        {
            ...INITIAL_STATE
        }
        this.toolbarRef = React.createRef();
        this.grab = null;
    }

    componentDidMount()
    {
        this.toolbarRef.current.addEventListener("mousedown", this.startDrag, true);
        document.onmouseup = this.stopDrag;
        this.props.focusCallback(this.props.index);
        // document.ontouchend = this.stopDrag;
    }


    componentWillUnmount()
    {
       
    }

    mouseEnter = (e) =>
    {
        let ref = e.target

        gsap.killTweensOf(ref);
        gsap.to(ref, {background: 'rgba(87, 87, 87, 1)', duration: .3})

    }


    mouseLeave = (e) =>
    {
        let ref = e.target

        gsap.killTweensOf(ref);
        gsap.to(ref, {background: 'rgba(87, 87, 87, 0.0)', duration: .3})
    }

    startDrag = (e) =>
    {
        this.grab = e.target;

        var rect = this.grab.getBoundingClientRect();

        if(e.type === "mousedown")
        {
            console.log('grabbed')
            this.xOffset = e.clientX - rect.left; //clientX and getBoundingClientRect() both use viewable area adjusted when scrolling aka 'viewport'
            this.yOffset = e.clientY - rect.top;
            document.onmousemove = this.dragWindow;
            document.onmouseup = this.stopDrag;
        }
        // else if(e.type=="touchstart")
        // {
        //     xOffset = e.targetTouches[0].clientX - rect.left; //clientX and getBoundingClientRect() both use viewable area adjusted when scrolling aka 'viewport'
        //     yOffset = e.targetTouches[0].clientY - rect.top;
        //     window.addEventListener('touchmove', this.dragWindow, true);
        // }
    }

    dragWindow = (e) =>
    {

        if(this.grab == null) return; // if there is no object being dragged then do nothing
        else if(e.type === "mousemove")
        {
            this.grab.parentNode.style.left = e.clientX-this.xOffset +"px"; // adjust location of dragged object so doesn't jump to mouse position
            this.grab.parentNode.style.top = e.clientY-this.yOffset +"px";
        }
        // else if(e.type=="touchmove")
        // {
        //     dragObj.style.left = e.targetTouches[0].clientX-xOffset +"px"; // adjust location of dragged object so doesn't jump to mouse position
        //     dragObj.style.top = e.targetTouches[0].clientY-yOffset +"px";
        // }
    }
    focusWindow = () =>
    {

        this.props.focusCallback(this.props.index)
    }
    
    stopDrag = (e) =>
    {

        if(this.grab) 
        {
            console.log("REMOVED")
            this.grab = null;
            document.onmousemove = null;
            // window.removeEventListener('touchmove', this.dragWindow, true);
        }
    }
    
    closeWindow = () =>
    {
        this.props.closeCallback(this.props.stringKey);
    }




    render()
    {
        return (
            <div className='WindowBorder' style={{zIndex: this.props.zLevel, top: 60 + 25*this.props.index +"px", left: 160 + 55*this.props.index + "px"}} onMouseDown={this.focusWindow}>
                <div className='TopBar' ref={this.toolbarRef}>
                    <div style={{width: 125}}/>

                    <p style={{pointerEvents: 'none'}} >
                        {this.props.title}  
                    </p>

                    <div className='WindowControls'>
                        <div className='WindowButtons' onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                            <p style={{pointerEvents: 'none', fontSize: '16px'}} className="material-icons">minimize</p>
                        </div>
                        <div className='WindowButtons' onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                            <p style={{pointerEvents: 'none', fontSize: '16px'}} className="material-icons">crop_square</p>
                        </div>
                        <div className='WindowButtons' onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave} onMouseDown={this.closeWindow}>
                            <p style={{pointerEvents: 'none', fontSize: '16px'}} className="material-icons">close</p>
                        </div>
                    </div>
                </div>
                <div>
                    {/* background stuff   */}
                </div>
            </div>
        )
    }
}




export default Window