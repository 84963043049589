import React, {useEffect, useState} from 'react'
import "../pages/styles.scss"

const week=["Sun ", "Mon ", "Tue ", "Wed ", "Thu ", "Fri ", "Sat ", "Sun "];
const month = ["Jan ", "Feb ", "Mar ", "Apr ", "May ", "Jun ",  "Jul ", "Aug ", "Sep ", "Oct ", "Nov ", "Dec "];


const convertedTime = date => (new Date().getHours() + 24) % 12 || 12; 
const getDateTime = () =>
{
    const day = new Date();
    return week[day.getDay()] + month[day.getMonth()] + day.getDate() + ", " + convertedTime() + ":" + (day.getMinutes() + "").padStart(2,"0") + ((day.getHours() < 12) ? "AM" : "PM")
}


const ToolbarTime = () => 
{

    
    const [time, setTime] = useState(getDateTime());

    const tick = () => 
    {
        setTime(getDateTime());
    }

    

    useEffect(() => {
        let ticker = setInterval( tick, 1000 );
        return () => {
            clearInterval(ticker)
        }
    })


    return (
        <div className={['text', 'noselect'].join(' ')}>
            <p>{time}</p>
        </div>
    )
}

export default ToolbarTime