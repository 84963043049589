// import Two from 'two.js';
import React, {useEffect} from 'react'
import ToolbarTime from './ToolbarTime';
import '../pages/styles.scss'




let Toolbar = () =>
{

  useEffect(() => {

    return () => {
      //cleanup
    }
  }, [])


  return (  
    <div id='test' className= "toolbar">
      <div className="leftToolbar">
        <p>Activities</p>
      </div>
      <ToolbarTime/>
      <div className="rightToolbar">
        <p style={{marginRight: 50}}> Brad Ritten </p>
        <svg xmlns="http://www.w3.org/2000/svg" width="10.693" height="16.762" viewBox="0 0 10.693 16.762"><path d="M15.086,7.839V9.764h.838v4.658h-.838v2.192H.506V7.839H15.086m-.419-.822H1.257A1.236,1.236,0,0,0,0,8.394v7.672A1.49,1.49,0,0,0,1.257,17.71h13.41a1.49,1.49,0,0,0,1.257-1.644v-.548h.21a.745.745,0,0,0,.629-.822V9.764a.745.745,0,0,0-.629-.822h-.21V8.394A1.236,1.236,0,0,0,14.667,7.017Zm-.484,1.925H1.923v6.576h12.26Z" transform="translate(-7.017 16.762) rotate(-90)" fill="#fff"/></svg>
        <p style={{marginLeft: 10}}>93%  &#11206;</p>
      </div>
    </div>
  )

}

Toolbar = React.memo(Toolbar);

export default Toolbar