import React, {useEffect} from 'react'
import "../pages/styles.scss"

const Popup = (props) =>
{
    useEffect((text) =>{
        
        return() => {}
    })


    return (
        <div id={props.id} className="popup" style={{opacity: 0}}>
            <p>{props.text}</p>
        </div>
    );
}


export default Popup