// import './App.css';
import React, {Component} from 'react';
import * as THREE from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import Two from 'two.js';
import { gsap } from "gsap";
import Toolbar from '../components/Toolbar'
import SideBar from '../components/SideBar'
import './styles.scss'
import Window from '../components/Window';

const INITIAL_STATE =
	{
		loading: true,
		mouseDown: false,

		//windows
		openWindows: {
			chrome: false,
			steam: false,
			code: false,
			console: false,
			git: false,
			contact: false
		},
		//windows zindex
		windowZ: [
			8,
			7,
			6,
			5,
			4,
			3,
		]
	};



class Home extends Component
{
	// static context = AppContext
	constructor(props)
	{
		super(props);
		this.state = {
			...INITIAL_STATE
		};
		this.canvasRef = React.createRef();
		this.twoRef = React.createRef();
		this.handleResize = this.handleResize.bind(this);
		this.mouseUp = this.mouseUp.bind(this);
		this.mouseDown = this.mouseDown.bind(this);

	}

	componentDidMount()
	{
		window.addEventListener('resize', this.handleResize, false);
		window.addEventListener('mousemove', this.interact)
		this.twoRef.current.addEventListener('mousedown', this.mouseDown)
		window.addEventListener('mouseup', this.mouseUp)
		this.canvasInit();
		this.geometryInit();
		for (var i = 0; i<60; i++) {
			this.generateCars();
		  };

		this.gameLoop(0);
	}

	componentWillUnmount()
	{
		window.removeEventListener('resize', this.handleResize, false);
		window.current.removeEventListener('mousemove', this.handleResize, false);
		this.twoRef.current.removeEventListener('mousedown', this.handleResize, false);
		window.current.removeEventListener('mouseup', this.handleResize, false);
	}

	changeWindowStack = (index) =>
	{
		let clone = this.state.windowZ.slice()  	
		console.log("BEFORE:", clone)
		
		let num = clone[index]
		console.log(num)

		clone = clone.map(value => (value > num) ? --value : value)
		clone[index] = 8;

		console.log("AFTER:", clone)
		
		this.setState({
			windowZ: clone
		})
	}

	mouseUp(e)
	{
		this.setState(
			{
				mouseDown: false
			}
		)

		this.mouseHighlight.width = 0;
		this.mouseHighlight.height = 0;
	}

	mouseDown(e)
	{
		this.mouseHighlight.position.set(e.clientX + this.mouseHighlight.width/2, e.clientY + this.mouseHighlight.height/2)
		this.mouseHighlight.initPos = this.mouseHighlight.position.clone();
		this.setState(
			{
				mouseDown: true
			}
		)
	}

	interact = (e) =>
	{
		this.mouse.x = ( e.clientX / window.innerWidth ) * 2 - 1;
		this.mouse.y = - ( e.clientY / window.innerHeight ) * 2 + 1;
		
		// this.raycast.setFromCamera( this.mouse, this.camera );
		// const intersects = this.raycast.intersectObject( this.backWall );
		// this.mouseLight.position.set(intersects[0].point.x, intersects[0].point.y, -24);
		// if(this.name)
		// {
		// 	this.name.lookAt(new THREE.Vector3(intersects[0].point.x, intersects[0].point.y, 24))
		// }

		if (this.state.mouseDown)
		{
			this.mouseHighlight.width = e.clientX - this.mouseHighlight.initPos.x; 
			this.mouseHighlight.height = e.clientY - this.mouseHighlight.initPos.y; 
			this.mouseHighlight.position.set(this.mouseHighlight.initPos.x + this.mouseHighlight.width/2, this.mouseHighlight.initPos.y + this.mouseHighlight.height/2)
		}
	};

	handleResize = () =>
	{
		// Top one might be useless
		// this.TopGUILayer.translation.set(0, 0);
		// this.BottomGUILayer.translation.set(window.innerWidth, window.innerHeight );
		const width = window.innerWidth;
		const height = window.innerHeight;

		this.camera.aspect = width / height;
		this.camera.updateProjectionMatrix();
		this.renderer.setSize(width, height);
		
		// this.GUI.translation.set(-window.innerWidth/2, -height/2 )
	};


	canvasInit = () =>
	{
		this.two = new Two({width: '100%', height: '100%'}).appendTo(this.twoRef.current);

		this.scene = new THREE.Scene();
		// this.scene.matrixAutoUpdate = false;
		this.clock = new THREE.Clock();
		this.mouse = new THREE.Vector2();
		this.raycast = new THREE.Raycaster();

		/*-=========================================
		=           Initialize Renderer            =
		=========================================-*/
		this.renderer = new THREE.WebGLRenderer({antialias: true, powerPreference: "high-performance"});
		this.renderer.setClearColor(0xFFCC00, 1);
		this.renderer.autoClear = false;
		this.renderer.toneMappingExposure = 2;
		this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 1));
		this.renderer.setSize(window.innerWidth, window.innerHeight);
		this.renderer.outputEncoding  = THREE.LinearEncoding;
		this.renderer.shadowMap.enabled = true; //SHADOWS OFF
		this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
		// this.renderer.toneMapping = THREE.ReinhardToneMapping;
		this.canvasRef.current.appendChild(this.renderer.domElement);
		/*-======================================-*/


		/*-=========================================
		=            Initialize Camera             =
		=========================================-*/
		this.camera = new THREE.PerspectiveCamera(
			25,
			window.innerWidth / window.innerHeight,
			1,
			500,
		);
		this.camera.position.set(
			0,
			2,
			15
		);
		this.camera.updateProjectionMatrix();
		/*-======================================-*/


		/*-=========================================
		=           Initialize Lighting            =
		=========================================-*/
		this.sun = new THREE.AmbientLight(0xFFFFFF, 4);
		this.frontLight = new THREE.SpotLight(0xFFFFFF, 20, 10);
		this.backLight = new THREE.PointLight(0xFFFFFF, 0.5);

		// let spotLightHelper = new THREE.SpotLightHelper(this.frontLight);
		// this.scene.add(spotLightHelper);

		this.frontLight.rotation.x = 45 * Math.PI / 180;
		this.frontLight.rotation.z = -45 * Math.PI / 180;
		this.frontLight.position.set(6, 6, 5);
		this.frontLight.castShadow = true;
		this.frontLight.shadow.mapSize.width = 6000;
		this.frontLight.shadow.mapSize.height = 6000;
		this.frontLight.penumbra = 0.1;
		this.backLight.position.set(0,6,0);

		this.scene.add(this.sun);
		this.scene.add(this.backLight);
		// this.sun.shadow.camera.updateProjectionMatrix();
		/*-======================================-*/
		

		this.mouseHighlight = new Two.Rectangle(0,0,0,0);
		this.mouseHighlight.fill = "DarkCyan"
		this.mouseHighlight.stroke = "DarkCyan"
		this.mouseHighlight.opacity = .2;
		this.two.add(this.mouseHighlight)
	}

	random = (num) =>
	{
		return (- Math.random() * num + Math.random() * num)
	}

	updateWindows = (key) => {
		console.log('close attempted', key)
		this.setState({
			openWindows: {
				...this.state.openWindows, 
				[key]: !this.state.openWindows[key]
			}
		})
	 }

	geometryInit = () =>
	{
		// this.loadModels(this.scene);

		this.city = new THREE.Object3D();
		this.town = new THREE.Object3D();
		this.smoke = new THREE.Object3D();

		/*-=========================================
		=             Initialize FOG               =
		=========================================-*/
		let fogColor = 0x1A2126
		this.scene.background = new THREE.Color(fogColor);
		this.scene.fog = new THREE.Fog(fogColor, 10, 16);
		//this.scene.fog = new THREE.FogExp2(fogColor, 0.05);
		this.smoke.position.y = 2;
		/*-======================================-*/

		let gridHelper = new THREE.GridHelper( 60, 120, 0xFF0000, 0x000000);
		this.city.add( gridHelper );

		/*-=========================================
		=          Initialize Buildings            =
		=========================================-*/
		let buildingColor = 0x000000;
		let buildingSegments = 2;
		for (let i = 1; i < 100; i++)
		{
			let geo = new THREE.CubeGeometry(1,0,0,buildingSegments,buildingSegments,buildingSegments);
			let mat = new THREE.MeshStandardMaterial({
				color: buildingColor,
				wireframe: false,
				shading: THREE.SmoothShading,
				side: THREE.FrontSide
			})
			let wireMat = new THREE.MeshLambertMaterial({
				color: 0xFFFFFF,
				transparent: true,
				wireframe: true,
				opacity: 0.03
			})

			let cube = new THREE.Mesh(geo, mat);
			let wire = new THREE.Mesh(geo,wireMat);
			let floor = new THREE.Mesh(geo, mat);
			let wireFloor = new THREE.Mesh(geo,wireMat);
			cube.add(wire);
			cube.add(wireFloor);
			cube.castShadow = true;
			cube.receiveShadow = true;
			cube.rotationValue = 0.1+Math.abs(this.random(8));

			floor.scale.y = 0.05;
			cube.scale.y = 0.1 + Math.abs(this.random(8));
			
			let cubeWidth = 0.9;
			cube.scale.x = cube.scale.z = cubeWidth + this.random(1-cubeWidth);
			cube.position.x = Math.round(this.random(8));
			cube.position.z = Math.round(this.random(8));

			floor.position.set(cube.position.x, 0, cube.position.z);

			this.town.add(floor);
			this.town.add(cube);
			this.scene.add(this.town);
		}

		let groundPart = new THREE.CircleGeometry(0.01, 3);
		let groundMat = new THREE.MeshToonMaterial({
			color:0xFF2000,
			side: THREE.FrontSide,
		})
		let aPart = 5;

		for (let i = 1; i < 300; i++)
		{
			let part = new THREE.Mesh(groundPart, groundMat);
			part.position.set(this.random(aPart),this.random(aPart), this.random(aPart));
			part.rotation.set(this.random(10), this.random(10), this.random(10))
			this.smoke.add(part);
		}

		let pGeo = new THREE.PlaneGeometry(60,60);
		var pMat = new THREE.MeshPhongMaterial({
			color: 0x000000,
			side: THREE.FrontSide,
			roughness: 10,
			metalness: 1,
			opacity: 0.5,
			transparent: true
		})
		let pElem = new THREE.Mesh(pGeo, pMat);
		pElem.rotation.x = -90 * Math.PI / 180;
		pElem.position.y = -0.001;
		pElem.receiveShadow = true;
		//pElem.material.emissive.setHex(0xFFFFFF + Math.random() * 100000);

		this.city.add(pElem)
		this.city.add(this.frontLight);
		this.city.add(this.smoke);
		this.city.add(this.town);
		this.scene.add(this.city);
		this.city.rotation.x = -0.1545;
		this.city.rotation.y = -0.76
		/*-======================================-*/
	};


	generateCars = (carScale = 0.1, carPos = 20, carColor = 0xFFFFAA) =>
	{
		let cGeo = new THREE.CubeGeometry(1, carScale/40, carScale/40);
		let cMat = new THREE.MeshToonMaterial({color: carColor, side: THREE.DoubleSide});
		let cElem = new THREE.Mesh(cGeo, cMat);
		let cAmp = 3;

		if (this.carFlag)
		{
			this.carFlag = false;
			cElem.position.x = -carPos;
			cElem.position.z = this.random(cAmp);
			gsap.to(cElem.position, {x:carPos, yoyo: true, delay: this.random(3), repeat: -1, duration: 3});
		}
		else 
		{
			this.carFlag = true;
			cElem.position.x = this.random(cAmp);
			cElem.position.z = -carPos;
			cElem.rotation.y = 90 * Math.PI / 180;
			gsap.to(cElem.position, {z: carPos, yoyo: true, delay: this.random(3), repeat: -1, duration: 5, ease: "power1.easeInOut"});
		}

		cElem.receiveShadow = true;
		cElem.castShadow = true;
		cElem.position.y = Math.abs(this.random(5));
		this.city.add(cElem);
	};


	loadModels = (scene) =>
	{
		let index = 0;
		const models = ['namae.glb'];
		let loader = new GLTFLoader();
		// var loader = new THREE.GLTFLoader();
		// THREE.DRACOLoader.setDecoderConfig({type: 'wasm'});
		// // Optional: Provide a DRACOLoader instance to decode compressed mesh data
		// THREE.DRACOLoader.setDecoderPath( 'decoder/' );
		// loader.setDRACOLoader( new THREE.DRACOLoader() );

		// Optional: Pre-fetch Draco WASM/JS module, to save time while parsing.
		// THREE.DRACOLoader.getDecoderModule();

		while (index < models.length)
		{
			this.loadNext(loader, models[index]);
			index++
		}


		const shadowPlane = new THREE.PlaneGeometry(200, 200);
		const shadowMaterial = new THREE.MeshStandardMaterial({    color: 0x222743, roughness: 10		});
		this.backWall = new THREE.Mesh(shadowPlane, shadowMaterial);
		this.backWall.receiveShadow = true;
		this.backWall.position.z= -1.6;
		this.scene.add(this.backWall);

		const light = new THREE.PointLight(0xa4a4a4,1);
		light.position.z = -24;
		this.scene.add(light);

		// this.GUI.translation.set(window.innerWidth/2, window.innerHeight/2 )
		// this.GUI.translation.set(-this.two.width/2 ,-this.two.height/2 );
		this.two.update();

	};

	loadNext = (loader, model) =>
	{
		// Load a glTF resource
		loader.load(
			// resource URL
			'/models/' + model,
			// called when the resource is loaded
			(gltf) =>
			{
				gltf.scene.position.y = 4;
				this.scene.add(gltf.scene);
				for (let i = 0; i < gltf.scene.children.length; i++)
				{
					// gltf.scene.children[i].matrixAutoUpdate = false;
					// gltf.scene.children[i].material = new THREE.MeshStandardMaterial({color: 0xFFFFFF, roughness: 1});
					gltf.scene.children[i].receiveShadow = true;
					gltf.scene.children[i].castShadow = true;
				}
				this.setState({loadingDone: true})
			})
	};

	update = (time) =>
	{
		// this.city.rotation.y = (Math.sin(time / 5000) * 13) * Math.PI / 100
		this.city.rotation.y -= ((this.mouse.x * 8) - this.camera.rotation.y) * 0.0001 //speed
		this.city.rotation.x -= (-(this.mouse.y * 2 ) - this.camera.rotation.x) * 0.0001 //speed
		if (this.city.rotation.x < -0.05) this.city.rotation.x = -0.05;
		else if (this.city.rotation.x > 1) this.city.rotation.x = 1;
		this.smoke.rotation.y += 0.001;
		this.smoke.rotation.x -= 0.001;


		// this.camera.lookAt(this.city.position);

	};

	gameLoop = (time) =>
	{
		this.frameNum = requestAnimationFrame(this.gameLoop);

		this.update(time);

		this.renderCanvas();
	};


	renderCanvas = () =>
	{
		this.renderer.clear();
		this.renderer.render(this.scene, this.camera);
		this.two.update();
	};

	render()
	{
		// const {		mobile	} = this.state;

		return (
			<div style={{overflow: 'hidden', position:'relative', width: '100vw', height: '100vh'}}>
				<Toolbar/>
				<SideBar updateWindows={this.updateWindows} openWindows={this.state.openWindows}/>
				<div className="fullscreen-twojs" ref={this.twoRef}/>
				<div
					id="canvas3d"
					ref={this.canvasRef}
					className='fullscreen-canvas'
				/>
				{this.state.openWindows.chrome && <Window title="Google.ca" zLevel={this.state.windowZ[0]} index={0} focusCallback={this.changeWindowStack} closeCallback={this.updateWindows} stringKey="chrome"/>}
				{this.state.openWindows.steam && <Window title="Steam Game Library" zLevel={this.state.windowZ[1]} index={1} focusCallback={this.changeWindowStack} closeCallback={this.updateWindows} stringKey="steam"/>}
				{this.state.openWindows.code && <Window title="Known Languages"zLevel={this.state.windowZ[2]} index={2} focusCallback={this.changeWindowStack} closeCallback={this.updateWindows} stringKey="code"/>}
				{this.state.openWindows.console && <Window title="BradRitten@Unix-Fake-Terminal"zLevel={this.state.windowZ[3]} index={3} focusCallback={this.changeWindowStack} closeCallback={this.updateWindows} stringKey="console"/>}
				{this.state.openWindows.git && <Window title="Known Librarys"zLevel={this.state.windowZ[4]} index={4} focusCallback={this.changeWindowStack} closeCallback={this.updateWindows} stringKey="git"/>}
				{this.state.openWindows.contact && <Window title="Contact Me"zLevel={this.state.windowZ[5]} index={5} focusCallback={this.changeWindowStack} closeCallback={this.updateWindows} stringKey="contact"/>}
			</div>
		)
	}
}

export default Home
